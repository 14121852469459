import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Ideas } from "../models/ideas.model";
import {Trades} from "../models/trades.model";
import {AngularFireStorage} from "@angular/fire/storage";
import {finalize} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IdeasService {
  public uploadPercent: Observable<number>;
  private downloadURL: Observable<string | null>

  constructor(private firestore: AngularFirestore, private firestorage: AngularFireStorage) {
  }

  getIdeas() {
    return this.firestore.collection("ideas").snapshotChanges();
  }

  createIdeas(trade: Ideas){
    return this.firestore.collection('ideas').add(trade);
  }

  createTrade(trade: Trades){
    delete trade.id;
    return this.firestore.collection('trades').add(trade);
  }

  updateIdeas(trade: Ideas){
    let position = trade.id;
    delete trade.id;
    this.firestore.doc('ideas/' + position).update(trade);
  }

  deleteIdeas(tradeID: string){
    this.firestore.doc('ideas/' + tradeID).delete();
  }

  uploadFile(data: File, fileID: string) {
    const filePath = 'images/' + fileID + '.png';
    const fileRef = this.firestorage.ref(filePath);
    const task = this.firestorage.upload(filePath, data);
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => this.downloadURL = fileRef.getDownloadURL())).subscribe()
  }

  getDownloadURL(id: string){
    const filePath = 'images/' + id + '.png';
    const fileRef = this.firestorage.ref(filePath);
    return this.downloadURL = fileRef.getDownloadURL();
  }
}
