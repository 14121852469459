<div class="container d-flex justify-content-center" style="margin-top: 70px;">
    <div class="card text-center">
        <div class="card-header">
            <h5 class="card-title">Login</h5>
        </div>
        <div class="card-body">
                <form name="userform" method="post" #formCtrl="ngForm">
                    <div class="form-group">
                        <label>Email address</label>
                        <input type="email" class="form-control" [(ngModel)]="user.email" name="email"
                               placeholder="Email" required>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" [(ngModel)]="user.password" name="password"
                               placeholder="Password" required>
                    </div>

                    <div class="form-group">
                        <button type="buton" class="btn btn-primary" style="margin-bottom: 20px"
                                (click)="signInWithEmail()" [disabled]="formCtrl.form.invalid">
                            Login
                        </button>
                    </div>
                </form>
        </div>
        <div class="card-footer text-muted">
            Version 1.3
        </div>
    </div>
</div>
