import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { IdeasService} from "../services/ideas.service";
import { Ideas as Trades} from "../models/ideas.model";
import {FlashMessagesService} from "angular2-flash-messages";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";

@Component({
  selector: 'app-idea-list',
  templateUrl: './idea-list.component.html',
  styleUrls: ['./idea-list.component.css']
})
export class IdeaListComponent implements OnInit {

  trades: Trades[] = [];
  newTrade: any = {
    ticker: "",
    contracts: 0,
    creationDate: "",
    earningsDate: "",
    earningsTime: "",
    call: {long: 0, short: 0},
    put: {long: 0, short: 0},
    buyPrice: 0,
    salePrice: null,
    daysOpen: null,
    impliedVol: 0,
    closingVol: null,
    status: 0,
    notes: ""
  };
  updateTrade: any = {
    ticker: '',
    contracts: 0,
    creationDate: "",
    earningsDate: "",
    earningsTime: '',
    call: {long: 0, short: 0},
    put: {long: 0, short: 0},
    buyPrice: 0,
    salePrice: null,
    daysOpen: null,
    impliedVol: 0,
    closingVol: null,
    status: 0,
    notes: ''
  };
  addNewTrade = false;
  editTrade = false;
  deleteTicker: any;
  deleteTrade = false;
  TradeCreateForm;
  TradeUpdateForm;
  selectedFile = null;
  downloadURL: Observable<string | null>
  openPhotoView = false;
  rowControls: any[] = [];
  totalProfit: number;

  constructor(
      public tradesService: IdeasService,
      private flashMessage: FlashMessagesService,
      private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.tradesService.getIdeas().subscribe(data => {
      this.trades = data.map(e => {
        const data = e.payload.doc.data();
        const id = e.payload.doc.id;
        this.rowControls.push({
          isCollapsed: true
        })
        return {
          id: id,
          ...(data as {})
        } as Trades;
      }).sort((one, two) => (one.creationDate > two.creationDate ? -1 : 1)).sort((one, two) => (two.status - one.status));
    });
    this.TradeCreateForm = new FormGroup({
      'tick': new FormControl(this.newTrade.ticker, [
        Validators.required
      ]),
      'longPut': new FormControl(this.newTrade.put.long, [
        Validators.required,
        Validators.min(0)
      ]),
      'shortPut': new FormControl(this.newTrade.put.short, [
        Validators.required,
        Validators.min(0)
      ]),
      'shortCall': new FormControl(this.newTrade.call.short, [
        Validators.required,
        Validators.min(0)
      ]),
      'longCall': new FormControl(this.newTrade.call.long, [
        Validators.required,
        Validators.min(0)
      ]),
      'contractSet': new FormControl(this.newTrade.contracts, [
        Validators.required,
        Validators.min(0)
      ]),
      'dateOpen': new FormControl(this.newTrade.creationDate, [
        Validators.required
      ]),
      'impliedVol': new FormControl(this.newTrade.impliedVol, [
        Validators.required,
        Validators.min(0)
      ]),
      'openPrice': new FormControl(this.newTrade.buyPrice, [
        Validators.required,
        Validators.min(0)
      ])
    });
    this.TradeUpdateForm = new FormGroup({
      'tick': new FormControl(this.updateTrade.ticker, [
        Validators.required
      ]),
      'longPut': new FormControl(this.updateTrade.put.long, [
        Validators.required,
        Validators.min(0)
      ]),
      'shortPut': new FormControl(this.updateTrade.put.short, [
        Validators.required,
        Validators.min(0)
      ]),
      'shortCall': new FormControl(this.updateTrade.call.short, [
        Validators.required,
        Validators.min(0)
      ]),
      'longCall': new FormControl(this.updateTrade.call.long, [
        Validators.required,
        Validators.min(0)
      ]),
      'contractSet': new FormControl(this.updateTrade.contracts, [
        Validators.required,
        Validators.min(0)
      ]),
      'dateOpen': new FormControl(this.updateTrade.creationDate, [
        Validators.required
      ]),
      'impliedVol': new FormControl(this.updateTrade.impliedVol, [
        Validators.required,
        Validators.min(0)
      ]),
      'openPrice': new FormControl(this.updateTrade.buyPrice, [
        Validators.required,
        Validators.min(0)
      ]),
      'closePrice': new FormControl(this.updateTrade.closePrice, [
        Validators.min(0)
      ]),
      'closingVol': new FormControl(this.updateTrade.closingVol, [
        Validators.min(0)
      ])
    });
  }

  create(trade: Trades) {
    this.tradesService.createIdeas(trade).then(r => {
    });
  }

  addTrade({value, valid}: { value: Trades, valid: boolean }) {
    if (!valid) {
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
    } else {
      let submit = this.newTrade;
      this.create(submit);
      this.addNewTrade = false;
    }
  }

  update(trade: Trades) {
    this.tradesService.updateIdeas(trade);
  }

  delete(id: string) {
    this.tradesService.deleteIdeas(id);
  }

  openNew() {
    this.addNewTrade = true;
    this.newTrade = {
      ticker: "",
      contracts: 0,
      creationDate: "",
      earningsDate: "",
      earningsTime: "",
      call: {long: 0, short: 0},
      put: {long: 0, short: 0},
      buyPrice: 0,
      salePrice: null,
      daysOpen: null,
      impliedVol: 0,
      closingVol: null,
      status: 0,
      notes: ""
    };
  }

  closeCreate() {
    this.addNewTrade = false;
  }

  openEdit(trade: Trades) {
    this.updateTrade = trade;
    this.editTrade = true;
  }

  closeEdit() {
    this.editTrade = false;
  }

  changeTrade({value, valid}: { value: Trades, valid: boolean }) {
    if (!valid) {
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
    } else {
      let submit = this.updateTrade;
      this.update(submit);
      this.editTrade = false;
    }
  }

  openDelete(trade: Trades) {
    this.deleteTicker = trade.id
    this.deleteTrade = true;
  }

  removeBeforeMove(trade: Trades) {
    this.deleteTicker = trade.id
    this.removeTrade()
  }

  removeTrade() {
    this.delete(this.deleteTicker);
    this.deleteTrade = false;
  }

  closeDelete() {
    this.deleteTrade = false;
  }

  moveGame(trade: Trades) {
    this.removeBeforeMove(trade);
    this.tradesService.createTrade(trade);
  }

  onFileSelected(event){
    this.selectedFile = event.target.files[0];
  }

  getPhoto(id: string){
    this.downloadURL = this.tradesService.getDownloadURL(id);
  }

  openPhoto(trade: Trades){
    this.openPhotoView = true;
    this.getPhoto(trade.id);
  }

  closePhoto(){
    this.openPhotoView = false;
  }

  openUpload = false;

  openDiv(i: number) {
    if (this.rowControls[i].isCollapsed){
      this.rowControls[i].isCollapsed = false;
    } else {
      this.rowControls[i].isCollapsed = true;
    }
  }

  uploadImage(trade: any) {
    this.updateTrade = trade;
    this.openUpload = true;
  }

  imageClose() {
    this.openUpload = false;
    window.location.reload();
  }

  imageUpload() {
    this.tradesService.uploadFile(this.selectedFile, this.updateTrade.id);
  }

  getTotalProfit(){
    this.totalProfit = 0;
    for (let value of this.trades){
      if(value.salePrice != null){
        let profit = ((value.buyPrice - value.salePrice) * value.contracts)
        this.totalProfit += profit
      }
    }
  }

  getProfit(trade: Trades) {
    let profit = ((trade.buyPrice - trade.salePrice) * trade.contracts)
    return profit
  }
}

