<div *ngIf="addNewTrade" class="cover">
    <div class="custom-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Add Trade</h2>
                </div>
                <div class="modal-body">
                    <form #TradeCreate="ngForm">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Ticker: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': tick.errors && tick.touched}"
                                        #tick="ngModel"
                                        required
                                        name="tick"
                                        class="form-control"
                                        type="text"
                                        [(ngModel)]="newTrade.ticker">

                                <div [hidden]="!tick.errors?.required" class="invalid-feedback">
                                    Ticker is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Long Put: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': longPut.errors && longPut.touched}"
                                        #longPut="ngModel"
                                        required
                                        name="longPut"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="newTrade.put.long">
                                <div [hidden]="!longPut.errors?.required" class="invalid-feedback">
                                    Long Put is required
                                </div>
                                <div [hidden]="!longPut.errors?.min" class="invalid-feedback">
                                    Long Put must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Short Put: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': shortPut.errors && shortPut.touched}"
                                        #shortPut="ngModel"
                                        required
                                        name="shortPut"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="newTrade.put.short">
                                <div [hidden]="!shortPut.errors?.required" class="invalid-feedback">
                                    Short Put is required
                                </div>
                                <div [hidden]="!shortPut.errors?.min" class="invalid-feedback">
                                    Short Put must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Short Call: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': shortCall.errors && shortCall.touched}"
                                        #shortCall="ngModel"
                                        required
                                        name="shortCall"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="newTrade.call.short">
                                <div [hidden]="!shortCall.errors?.required" class="invalid-feedback">
                                    Short Call is required
                                </div>
                                <div [hidden]="!shortCall.errors?.min" class="invalid-feedback">
                                    Short Call must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Long Call: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': longCall.errors && longCall.touched}"
                                        #longCall="ngModel"
                                        required
                                        name="longCall"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="newTrade.call.long">
                                <div [hidden]="!longCall.errors?.required" class="invalid-feedback">
                                    Long Call is required
                                </div>
                                <div [hidden]="!longCall.errors?.min" class="invalid-feedback">
                                    Long Call must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contracts: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': contract.errors && contract.touched}"
                                        #contract="ngModel"
                                        required
                                        name="contractSet"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="newTrade.contracts">
                                <div [hidden]="!contract.errors?.required" class="invalid-feedback">
                                    Contracts is required
                                </div>
                                <div [hidden]="!contract.errors?.min" class="invalid-feedback">
                                    Contracts must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date Opened: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': dateOpen.errors && dateOpen.touched}"
                                        #dateOpen="ngModel"
                                        required
                                        name="dateOpen"
                                        class="form-control"
                                        type="date"
                                        [(ngModel)]="newTrade.creationDate">
                                <div [hidden]="!dateOpen.errors?.required" class="invalid-feedback">
                                    Date Opened is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Earnings Date: </label>
                            <div class="col-sm-9">
                                <input
                                    #dateEarning="ngModel"
                                    name="earningDate"
                                    class="form-control"
                                    type="date"
                                    [(ngModel)]="newTrade.earningsDate">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Earnings Time: </label>
                            <div class="col-sm-9">
                                <input
                                    #timeEarnings="ngModel"
                                    name="earningsTime"
                                    class="form-control"
                                    type="text"
                                    [(ngModel)]="newTrade.earningsTime">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Implied Volatility Open: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': impliedVol.errors && impliedVol.touched}"
                                        #impliedVol="ngModel"
                                        required
                                        name="impliedVol"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="newTrade.impliedVol">
                                <div [hidden]="!impliedVol.errors?.required" class="invalid-feedback">
                                    Implied Volatility Open is required.
                                </div>
                                <div [hidden]="!impliedVol.errors?.min" class="invalid-feedback">
                                    Implied Volatility must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Open Price: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': openPrice.errors && openPrice.touched}"
                                        #openPrice="ngModel"
                                        required
                                        name="openPrice"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="newTrade.buyPrice">
                                <div [hidden]="!openPrice.errors?.required" class="invalid-feedback">
                                    Open Price is required
                                </div>
                                <div [hidden]="!openPrice.errors?.min" class="invalid-feedback">
                                    Open Price must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Notes: </label>
                            <div class="col-sm-9">
                                <input
                                    #notes="ngModel"
                                    name="note"
                                    class="form-control"
                                    type="text"
                                    [(ngModel)]="newTrade.notes">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status: </label>
                            <div class="col-sm-9">
                                <select
                                        name="status"
                                        id="statusSelect"
                                        class="custom-select"
                                        [(ngModel)]="newTrade.status">
                                    <option value="-1">Closed</option>
                                    <option value="1"> Open</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <ul class="form-buttons">
                                <li>
                                    <button class="btn btn-success" (click)="addTrade(TradeCreate)">Save</button>
                                </li>
                                <li>
                                    <button class="btn btn-danger" (click)="closeCreate()">Close</button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="editTrade" class="cover">
    <div class="custom-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Edit Data</h2>
                </div>
                <div class="modal-body">
                    <form #TradeEdit="ngForm">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Ticker: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': tick.errors && tick.touched}"
                                        #tick="ngModel"
                                        required
                                        name="tick"
                                        class="form-control"
                                        type="text"
                                        [(ngModel)]="updateTrade.ticker">
                                <div [hidden]="!tick.errors?.required" class="invalid-feedback">
                                    Ticker is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Long Put: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': longPut.errors && longPut.touched}"
                                        #longPut="ngModel"
                                        required
                                        name="longPut"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.put.long">
                                <div [hidden]="!longPut.errors?.required" class="invalid-feedback">
                                    Long Put is required
                                </div>
                                <div [hidden]="!longPut.errors?.min" class="invalid-feedback">
                                    Long Put must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Short Put: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': shortPut.errors && shortPut.touched}"
                                        #shortPut="ngModel"
                                        required
                                        name="shortPut"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.put.short">
                                <div [hidden]="!shortPut.errors?.required" class="invalid-feedback">
                                    Short Put is required
                                </div>
                                <div [hidden]="!shortPut.errors?.min" class="invalid-feedback">
                                    Short Put must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Short Call: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': shortCall.errors && shortCall.touched}"
                                        #shortCall="ngModel"
                                        required
                                        name="shortCall"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.call.short">
                                <div [hidden]="!shortCall.errors?.required" class="invalid-feedback">
                                    Short Call is required
                                </div>
                                <div [hidden]="!shortCall.errors?.min" class="invalid-feedback">
                                    Short Call must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Long Call: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': longCall.errors && longCall.touched}"
                                        #longCall="ngModel"
                                        required
                                        name="longCall"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.call.long">
                                <div [hidden]="!longCall.errors?.required" class="invalid-feedback">
                                    Long Call is required
                                </div>
                                <div [hidden]="!longCall.errors?.min" class="invalid-feedback">
                                    Long Call must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contracts: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': contract.errors && contract.touched}"
                                        #contract="ngModel"
                                        required
                                        name="contract"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.contracts">
                                <div [hidden]="!contract.errors?.required" class="invalid-feedback">
                                    Contracts is required
                                </div>
                                <div [hidden]="!contract.errors?.min" class="invalid-feedback">
                                    Contracts must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date Opened: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': dateOpen.errors && dateOpen.touched}"
                                        #dateOpen="ngModel"
                                        required
                                        name="dateOpen"
                                        class="form-control"
                                        type="date"
                                        [(ngModel)]="updateTrade.creationDate">
                                <div [hidden]="!dateOpen.errors?.required" class="invalid-feedback">
                                    Date Opened is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Earnings Date: </label>
                            <div class="col-sm-9">
                                <input
                                    #dateEarning="ngModel"
                                    name="dateEarning"
                                    class="form-control"
                                    type="date"
                                    [(ngModel)]="updateTrade.earningsDate">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Earnings Time: </label>
                            <div class="col-sm-9">
                                <input
                                    #timeEarnings="ngModel"
                                    name="earningsTime"
                                    class="form-control"
                                    type="text"
                                    [(ngModel)]="updateTrade.earningsTime">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Implied Volatility Open: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': impliedVol.errors && impliedVol.touched}"
                                        #impliedVol="ngModel"
                                        required
                                        name="impliedVol"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.impliedVol">
                                <div [hidden]="!impliedVol.errors?.required" class="invalid-feedback">
                                    Implied Volatility Open is required.
                                </div>
                                <div [hidden]="!impliedVol.errors?.min" class="invalid-feedback">
                                    Implied Volatility must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Open Price: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': openPrice.errors && openPrice.touched}"
                                        #openPrice="ngModel"
                                        required
                                        name="openPrice"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.buyPrice">
                                <div [hidden]="!openPrice.errors?.required" class="invalid-feedback">
                                    Open Price is required
                                </div>
                                <div [hidden]="!openPrice.errors?.min" class="invalid-feedback">
                                    Open Price must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Close Price: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': closePrice.errors && closePrice.touched}"
                                        #closePrice="ngModel"
                                        name="closePrice"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.salePrice">
                                <div [hidden]="!closePrice.errors?.min" class="invalid-feedback">
                                    Close Price must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Implied Volatility Close: </label>
                            <div class="col-sm-9">
                                <input
                                        [ngClass]="{'is-invalid': closingVol.errors && closingVol.touched}"
                                        #closingVol="ngModel"
                                        name="closingVol"
                                        class="form-control"
                                        type="number"
                                        min="0"
                                        [(ngModel)]="updateTrade.closingVol">
                                <div [hidden]="!closingVol.errors?.min" class="invalid-feedback">
                                    Close Price must be greater than 0.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date Closed: </label>
                            <div class="col-sm-9">
                                <input
                                    #dateClosed="ngModel"
                                    name="dayClosed"
                                    class="form-control"
                                    type="date"
                                    [(ngModel)]="updateTrade.daysOpen">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Notes: </label>
                            <div class="col-sm-9">
                                <input
                                    #notes="ngModel"
                                    name="note"
                                    class="form-control"
                                    type="text"
                                    [(ngModel)]="updateTrade.notes">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status: </label>
                            <div class="col-sm-9">
                                <select
                                        name="status"
                                        id="statusSelection"
                                        class="custom-select"
                                        [(ngModel)]="updateTrade.status">
                                    <option value="-1">Closed</option>
                                    <option value="1"> Open</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <ul class="form-buttons">
                                <li>
                                    <button class="btn btn-success" (click)="changeTrade(TradeEdit)">Save</button>
                                </li>
                                <li>
                                    <button class="btn btn-danger" (click)="closeEdit()">Close</button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="openUpload" class="cover">
    <div class="custom-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Upload Image</h2>
                </div>
                <div class="modal-body">
                    <form #imageSend="ngForm">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Upload Chart: </label>
                            <div class="col-sm-9">
                                <input type="file" class="form-control-file" (change)="onFileSelected($event)" accept=".png"/>
                                <progress max="100" [value]="(tradesService.uploadPercent | async)"></progress>
                            </div>
                        </div>
                        <div class="form-group">
                            <ul class="form-buttons">
                                <li>
                                    <button class="btn btn-success" (click)="imageUpload()">Save</button>
                                </li>
                                <li>
                                    <button class="btn btn-danger" (click)="imageClose()">Close</button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="deleteTrade" class="cover">
    <div class="custom-modal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Confirm Delete</h2>
                </div>
                <div class="modal-body">
                    <form #updateGroupDeleteForm="ngForm">
                        <div class="form-group">
                            <ul class="form-buttons">
                                <li>
                                    <button class="btn btn-success" (click)="removeTrade()">Yes</button>
                                </li>
                                <li>
                                    <button class="btn btn-danger" (click)="closeDelete()">No</button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="openPhotoView" class="cover">
    <div class="custom-modal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <form #openPhotoViewForm="ngForm">
                        <img class="img-fluid" style="object-fit: cover;" src="{{downloadURL | async}}">
                        <div class="form-group">
                            <ul class="form-buttons">
                                <li>
                                    <button class="btn btn-danger" style="margin-top: 15px" (click)="closePhoto()">Close</button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="col-md-12 mt-4 mb-4">
        <button (click)="openNew()" style="float:right;" class="btn btn-primary">Add Trade
        </button>
        <h1>Trades</h1>
        <div style="float:right; margin-bottom: 5px;">
            <div *ngIf="this.totalProfit >= 0">
                <span class="badge badge-success">Total Gains: {{this.totalProfit}}</span>
            </div>
            <div *ngIf="this.totalProfit < 0">
                <span class="badge badge-danger">Total Gains: {{this.totalProfit}}</span>
            </div>
        </div>
    <table class="table table-striped" [mfData]="trades" #mf="mfDataTable" [mfRowsOnPage]="10">
        <thead>
        <tr>
            <th scope="col">Ticker</th>
            <th scope="col">Long Put</th>
            <th scope="col">Short Put</th>
            <th scope="col">Short Call</th>
            <th scope="col">Long Call</th>
            <th scope="col">Contracts</th>
            <th scope="col">Date Opened</th>
            <th scope="col">Earnings Date</th>
            <th scope="col">Earnings Time</th>
            <th scope="col">Open Price</th>
            <th scope="col">Close Price</th>
            <th scope="col">Profit</th>
            <th scope="col">Date Closed</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
            <th scope="col"></th>
        </tr>
        </thead>
        <tbody *ngFor="let trade of mf.data; let i = index" [attr.data-index]="i">
        <tr>
            <th scope="row">
                <a href="https://uk.tradingview.com/symbols/{{trade.ticker}}" target="_blank">
                    {{trade.ticker}}
                </a>
            </th>
            <td>
                <div *ngIf="trade.put.long != 0">
                    <span class="badge badge-success">{{trade.put.long}}</span>
                </div>
            </td>
            <td>
                <div *ngIf="trade.put.short != 0">
                    <span class="badge badge-danger">{{trade.put.short}}</span>
                </div>
            </td>
            <td>
                <div *ngIf="trade.call.short != 0">
                    <span class="badge badge-danger">{{trade.call.short}}</span>
                </div>
            </td>
            <td>
                <div *ngIf="trade.call.long != 0">
                    <span class="badge badge-success">{{trade.call.long}}</span>
                </div>
            </td>
            <td>{{trade.contracts}}</td>
            <td>{{trade.creationDate | date:'dd/MM/yyyy'}}</td>
            <td>{{trade.earningsDate | date:'dd/MM/yyyy'}}</td>
            <td>{{trade.earningsTime}}</td>
            <td>{{trade.buyPrice}}</td>
            <td>{{trade.salePrice}} <br> Goal: {{((trade.buyPrice * 0.55) | number: '1.0-0')}}</td>
            <td>
                <div *ngIf="trade.salePrice != null">
                    <div *ngIf="((trade.buyPrice - trade.salePrice) * trade.contracts) >= 0">
                        <span class="badge badge-success">{{getProfit(trade)}}</span>
                    </div>
                    <div *ngIf="((trade.buyPrice - trade.salePrice) * trade.contracts) < 0">
                        <span class="badge badge-danger">{{getProfit(trade)}}</span>
                    </div>
                </div>
            </td>
            <td>{{trade.daysOpen | date:'dd/MM/yyyy'}}</td>
            <td>
                <div *ngIf="trade.status == -1">
                    <span class="badge badge-danger">Closed</span>
                </div>
                <div *ngIf="trade.status == 1">
                    <span class="badge badge-primary">Open</span>
                </div>
            </td>
            <td>
                <button (click)="openEdit(trade)" class="btn btn-primary btn-sm">Edit</button>
            </td>
            <td>
                <button type="button" class="btn btn-outline-primary btn-sm" (click)="openDiv(i)">More</button>
            </td>
        </tr>
        <tr [ngbCollapse]="rowControls[i].isCollapsed">

            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td scope="col"></td>
            <td></td>
            <td></td>
            <td><b>Open Vol: </b>{{trade.impliedVol}}</td>
            <td><b>Closing Vol: </b>{{trade.closingVol}}</td>
            <td></td>
            <td>
                {{trade.notes}}
            </td>
            <td>
                <button (click)="uploadImage(trade)" class="btn btn-primary btn-sm">Upload</button>
            </td>
            <td>
                <button (click)="openPhoto(trade)" class="btn btn-primary btn-sm">View</button>
            </td>
            <td>
                <button (click)="openDelete(trade)" class="btn btn-danger btn-sm">Delete</button>
            </td>
        </tr>
            <div *ngIf="i == mf.data.length-1">
                <span>{{getTotalProfit()}}</span>
            </div>
        </tbody>
        <tfoot>
        <tr>
            <td colspan="19">
                <mfBootstrapPaginator></mfBootstrapPaginator>
            </td>
        </tr>
        </tfoot>
    </table>
</div>
</div>
