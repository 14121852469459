<div class="jumbotron jumbotron-fluid" style="width: 75rem">
    <div class="container-xl">
    <h1 class="display-5">Welcome {{authService.username}}</h1>
    <p class="lead">This is the new Options Panel Here you can modify your trades.</p>
    <hr class="my-4">
    <!--  <p>If you're looking for the legacy admin panel for game creation the link can be found below.</p>-->
    <!--  <p class="lead">-->
    <!--    <a class="btn btn-danger btn-lg" href="http://54.76.200.123:9010/retrader" role="button">Legacy Admin Panel</a>-->
    <!--  </p>-->
    <div style="position: relative; width: 100%; margin: 0 auto">
      <iframe src="https://marketchameleon.com/Widget/Show?wtype=ecfull" style="border:0px #FFFFFF none;" scrolling="yes" frameborder="0" height="965px" width="100%"></iframe>
    </div>
    </div>
</div>

