<div *ngIf="authService.isLoggedIn()">
  <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/">Option Panel</a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="navbar-nav mr-auto">
        <a class="nav-link" routerLink="/">Home <span class="sr-only">(current)</span></a>
        <li ngbDropdown class="nav-item">
          <a href (click)="false" class="nav-link" ngbDropdownToggle>Earnings Trades</a>
          <div ngbDropdownMenu>
            <button ngbDropdownItem routerLink="/trades">Trades</button>
             <div class="dropdown-divider"></div>
            <button ngbDropdownItem routerLink="/ideas">Ideas</button>
          </div>
        </li>

      </div>
      <div class="navbar-nav">
        <button class="btn btn-sm btn-outline-danger" (click)="authService.logout()">Log Out</button>
      </div>

    </div>
  </nav>
  <iframe id='st_36612f2440464d71bb236e2cefe9329b' style="height: 25px" frameBorder='0' scrolling='no' width='100%' height='100%' src='https://api.stockdio.com/visualization/financial/charts/v1/Ticker?app-key=2E4E6A1C1A1545D0865D11498FA15A1E&symbols=SPY;GLD;QQQ;VXX;XLE;XLK;XLF;XLU;XLP;XLV;XLB;XLRE;XLY;XLC&palette=Financial-Light&onload=st_36612f2440464d71bb236e2cefe9329b'></iframe>
</div>

